import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useValidationState } from '../../../../../../state/hooks/DatastoreHooks';
import { DataGridTable, TableErrorDetails } from './components';

export const TableDetails = ({ fileName, storagePath }) => {
  const validation = useValidationState();
  const tableValidation = useMemo(() => validation.tables?.[fileName] ?? {}, [fileName, validation]);

  return (
    <Box sx={{ gap: 2, p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5">{fileName.split('.').slice(0, -1).join('.')}</Typography>
      <TableErrorDetails validation={tableValidation} />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '160px' }}>
        <DataGridTable storagePath={storagePath} />
      </Box>
    </Box>
  );
};

TableDetails.propTypes = {
  fileName: PropTypes.string.isRequired,
  storagePath: PropTypes.string.isRequired,
};
