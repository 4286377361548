import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Download, Delete, MoreVert } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { FileBlobUtils } from '@cosmotech/core';
import { FadingTooltip } from '@cosmotech/ui';
import {
  useDatasetStorageRoot,
  useDeleteDatastoreFiles,
  useIsArchiveAvailable,
  useIsReportAvailable,
  useSetValidationState,
  useUploadDatastoreFiles,
  useValidationState,
} from '../../../../../../state/hooks/DatastoreHooks';
import { useDataStoreFileDownload } from '../../../../DataStoreHooks';

const DownloadButton = ({ name, storagePath, handleClose }) => {
  const { t } = useTranslation();
  const downloadFile = useDataStoreFileDownload();
  const handleDownload = useCallback(() => {
    handleClose();
    downloadFile(storagePath)
      .then((data) => FileBlobUtils.downloadFileFromData(data.data, name))
      .catch((error) => console.error(error));
  }, [downloadFile, storagePath, name, handleClose]);

  return (
    <MenuItem onClick={handleDownload}>
      <Download />
      {t('commoncomponents.datastore.filesList.fileOptions.downloadAsCsv', 'Download as CSV')}
    </MenuItem>
  );
};

DownloadButton.propTypes = {
  name: PropTypes.string.isRequired,
  storagePath: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const DeleteButton = ({ name, storagePath, datasetId, handleClose }) => {
  const { t } = useTranslation();
  const uploadFiles = useUploadDatastoreFiles();
  const deleteFiles = useDeleteDatastoreFiles();
  const storageRoot = useDatasetStorageRoot();
  const isArchiveAvailable = useIsArchiveAvailable();
  const isReportAvailable = useIsReportAvailable();
  const validation = useValidationState();
  const setValidationState = useSetValidationState();

  const handleDelete = useCallback(() => {
    handleClose();
    try {
      const filePathsToDelete = [storagePath];
      if (isArchiveAvailable) filePathsToDelete.push(`${storageRoot}datastore.zip`);
      if (isReportAvailable) filePathsToDelete.push(`${storageRoot}validation_report.txt`);
      deleteFiles(filePathsToDelete);

      const updatedValidation = { ...validation, status: validation.status === 'draft' ? 'draft' : 'modified' };
      const fileData = new File([new Blob([JSON.stringify(updatedValidation)])], '_validation.json');
      uploadFiles([{ data: fileData, path: storageRoot }]);
      setValidationState(updatedValidation);
    } catch (error) {
      console.error(error);
    }
  }, [
    storagePath,
    handleClose,
    deleteFiles,
    uploadFiles,
    storageRoot,
    validation,
    isArchiveAvailable,
    isReportAvailable,
    setValidationState,
  ]);

  return (
    <MenuItem onClick={handleDelete} sx={{ color: 'red' }}>
      <Delete />
      {t('commoncomponents.datastore.filesList.fileOptions.delete', 'Delete')} {name.split('.').slice(0, -1).join('.')}
    </MenuItem>
  );
};

DeleteButton.propTypes = {
  name: PropTypes.string.isRequired,
  storagePath: PropTypes.string.isRequired,
  datasetId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export const FileOptions = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <FadingTooltip title={t('commoncomponents.datastore.filesList.fileOptions.tooltip', 'Additional file options')}>
        <Button onClick={handleClick}>
          <MoreVert />
        </Button>
      </FadingTooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <DownloadButton name={props.name} storagePath={props.storagePath} handleClose={handleClose} />
        <DeleteButton
          name={props.name}
          storagePath={props.storagePath}
          datasetId={props.datasetId}
          handleClose={handleClose}
        />
      </Menu>
    </div>
  );
};

FileOptions.propTypes = {
  name: PropTypes.string.isRequired,
  storagePath: PropTypes.string.isRequired,
  datasetId: PropTypes.string.isRequired,
};
