import { t } from 'i18next';
import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASTORE_ACTIONS } from '../../../commons/DatastoreConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

const getWorkspaceId = (state) => state.workspace.current?.data?.id;
const getOrganizationId = (state) => state.organization.current?.data?.id;

function* deleteWorkspaceFile(organizationId, workspaceId, filePath) {
  try {
    yield call(Api.Workspaces.deleteWorkspaceFile, organizationId, workspaceId, filePath);
  } catch (error) {
    console.error(error);
  }
}

/*
Action parameters:
- filePaths: list of file paths to delete from workspace files
*/
export function* deleteDatastoreFiles(action) {
  const workspaceId = yield select(getWorkspaceId);
  const organizationId = yield select(getOrganizationId);

  try {
    const filePaths = action.filePaths;
    yield all(filePaths.map((filePath) => call(deleteWorkspaceFile, organizationId, workspaceId, filePath)));
    yield put({ type: DATASTORE_ACTIONS.REMOVE_WORKSPACE_FILE_PATHS, workspaceFilePaths: filePaths });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t(
          'commoncomponents.datastore.errors.cannotDeleteWorkspaceFiles',
          'A problem occurred when deleting workspace files.'
        )
      )
    );
  }
}

function* deleteDatastoreFilesSaga() {
  yield takeEvery(DATASTORE_ACTIONS.TRIGGER_SAGA_DELETE_DATASTORE_FILES, deleteDatastoreFiles);
}

export default deleteDatastoreFilesSaga;
