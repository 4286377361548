import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Add, Delete, Edit, Cancel, Done } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Typography,
  Checkbox,
  FormControl,
} from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { TableCellComponent } from './TableCellComponent';

const EditableTable = ({ columns, rows, editMode, onChange, notFilteredRows, filteringCol }) => {
  const [editIdx, setEditIdx] = useState(-1);
  const [currentRow, setCurrentRow] = useState({});
  const [isNewRow, setIsNewRow] = useState(false);

  const rowsList = useMemo(() => {
    return notFilteredRows ?? rows;
  }, [notFilteredRows, rows]);

  const handleEdit = (idx) => {
    if (editIdx !== -1 && !isRowValid(rows[editIdx])) {
      return;
    }
    setEditIdx(idx);
    setCurrentRow(rows[idx]);
    setIsNewRow(false);
  };

  const handleSave = () => {
    if (!isRowValid(currentRow)) return;
    const index = rowsList.findIndex((row) => row.id === currentRow.id);
    if (index === -1) onChange([...rowsList, currentRow]);
    else {
      const valueCopy = [...rowsList];
      valueCopy[index] = { ...currentRow };
      onChange(valueCopy);
    }
    setEditIdx(-1);
    setIsNewRow(false);
  };

  const handleCancel = () => {
    if (isNewRow) {
      handleDeleteRow(currentRow.id);
    }
    setEditIdx(-1);
    setIsNewRow(false);
  };

  const handleChange = (column, value) => {
    const field = column.field;
    const currentValue = currentRow[field];
    if (currentValue === value) return;
    if (column && column.inputType === 'number') {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        if (column.min !== undefined && numValue < column.min) {
          value = column.min;
        }
        if (column.max !== undefined && numValue > column.max) {
          value = column.max;
        }
      }
    }
    setCurrentRow({ ...currentRow, [field]: value });
  };

  const handleAddRow = () => {
    if (editMode) {
      const newRow = { id: generateUniqueId('r') };
      if (filteringCol) newRow[filteringCol?.field] = filteringCol?.value;
      columns.forEach((col) => (newRow[col.field] = ''));
      onChange([...rowsList, newRow]);
      setEditIdx(rows.length);
      setCurrentRow(newRow);
      setIsNewRow(true);
    }
  };

  const handleDeleteRow = (id) => {
    if (rowsList.length > 1) onChange(rowsList.filter((row) => row.id !== id));
    else {
      const lastDeletedRow = {};
      Object.keys(rowsList[0]).forEach((col) => {
        lastDeletedRow[col] = null;
      });
      onChange([lastDeletedRow]);
    }
  };

  const generateUniqueId = (type) => {
    let newId;
    do {
      const randomString = Math.random().toString(36).substring(2, 15);
      newId = `${type}-${randomString}`;
    } while (rows.some((row) => row.id === newId)); // Assurer l'unicité de l'ID
    return newId;
  };

  const isRowValid = (row) => {
    return row != null && typeof row === 'object';
  };

  const isCellDisabled = useCallback(
    (col) => {
      return typeof col.disabled === 'function' ? col.disabled(currentRow) : col.disabled;
    },
    [currentRow]
  );

  return (
    <Box sx={{ marginTop: 2 }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow key="header" sx={{ display: 'flex' }}>
              {columns.map((col) => (
                <TableCell key={col.field} sx={{ width: `${100 / (columns.length + 1)}%` }}>
                  <Typography fontWeight={'bold'}>{col.label}</Typography>
                </TableCell>
              ))}
              <TableCell sx={{ width: `${100 / (columns.length + 1)}%` }}>
                <Typography fontWeight={'bold'}>{'Actions'}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, idx) => (
              <TableRow key={row.id} sx={{ display: 'flex', alignContent: 'center' }}>
                {columns.map((col) => (
                  <TableCell key={col.field} sx={{ display: 'inline-block', width: `${100 / (columns.length + 1)}%` }}>
                    {col.inputType === 'button' ? (
                      <Button onClick={() => col.onClick(row)}>{col.buttonLabel}</Button>
                    ) : col.inputType === 'checkbox' ? (
                      <Checkbox
                        // eslint rule for strict equality is disabled because parsing of csv file returns only strings
                        // while twingraph parsing returns booleans as well, so we need to compare both types
                        // eslint-disable-next-line eqeqeq
                        checked={editIdx !== idx ? row[col.field] == true : currentRow[col.field] == true}
                        onChange={(e) => handleChange(col, e.target.checked)}
                        disabled={editIdx !== idx}
                      />
                    ) : editIdx === idx ? (
                      <FormControl sx={{ width: '100%' }} disabled={isCellDisabled(col)}>
                        <TableCellComponent
                          col={col}
                          handleChange={handleChange}
                          currentRow={currentRow}
                          isCellDisabled={isCellDisabled}
                        />
                      </FormControl>
                    ) : (
                      <FadingTooltip useSpan={true} title={row[col.field]?.length > 8 ? row[col.field] : ''}>
                        <Typography sx={{ maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {row[col.field]}
                        </Typography>
                      </FadingTooltip>
                    )}
                  </TableCell>
                ))}
                <TableCell sx={{ display: 'inline-block', width: `${100 / (columns.length + 1)}%` }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {editIdx === idx ? (
                      <>
                        <IconButton onClick={handleSave} disabled={!isRowValid(currentRow)}>
                          <Done />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                          <Cancel />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {editMode && (
                          <IconButton onClick={() => handleEdit(idx)} disabled={editIdx !== -1}>
                            <Edit />
                          </IconButton>
                        )}
                        {editMode && (
                          <IconButton onClick={() => handleDeleteRow(row.id)} disabled={editIdx !== -1}>
                            <Delete />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell sx={{ borderBottom: 0 }} colSpan={columns.length + 1} align="right">
                <Button variant="contained" color="secondary" onClick={handleAddRow} disabled={!editMode}>
                  <Add />
                  Ajouter une ligne
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

EditableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      inputType: PropTypes.oneOf(['text', 'select', 'autocomplete', 'number', 'checkbox', 'multiSelect', 'button']),
      options: PropTypes.array,
    })
  ).isRequired,
  rows: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  notFilteredRows: PropTypes.array,
  filteringCol: PropTypes.object,
};

EditableTable.defaultProps = {
  editMode: true,
};

export default EditableTable;
