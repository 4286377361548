import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  CircularProgress,
  Typography,
  Box,
  Card,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  useDatastoreTables,
  useIsLoading,
  useSelectedDataset,
  useValidationState,
} from '../../../../state/hooks/DatastoreHooks';
import { UploadFileButtons, FileOptions } from '../DataStoreItemDetails/components';
import { FileStatusIcon } from './components';
import { TableDetails } from './components/TableDetails';

const DEFAULT_TABLE_STATE = { fileName: null, fileStoragePath: null };

export const DataStoreTablesList = () => {
  const { t } = useTranslation();
  const validation = useValidationState();
  const dataset = useSelectedDataset();
  const isDatastoreLoading = useIsLoading();
  const datastoreTables = useDatastoreTables();
  const [tablesBackdropOpen, setTablesBackdropOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState(DEFAULT_TABLE_STATE);

  useEffect(() => {
    setSelectedTable(DEFAULT_TABLE_STATE);
    const isRunning = Object.values(dataset?.associatedRunners ?? {}).some((runner) =>
      ['Unknown', 'Running'].includes(runner.state)
    );
    setTablesBackdropOpen(isRunning);
  }, [dataset]);

  const tablesList = useMemo(() => {
    return datastoreTables.map((file) => {
      const fileStatus = validation.tables[file.name]?.status;
      return (
        <Box key={file.storagePath} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ListItemButton
            onClick={() => setSelectedTable({ fileStoragePath: file.storagePath, fileName: file.name })}
            dense
            sx={{ flexGrow: 1 }}
            selected={selectedTable.fileName === file.name}
          >
            <ListItem sx={{ flexGrow: 1 }}>
              <ListItemIcon>
                <FileStatusIcon status={fileStatus} />
              </ListItemIcon>
              <ListItemText primary={file.name.split('.').slice(0, -1).join('.')} />
            </ListItem>
          </ListItemButton>
          <FileOptions name={file.name} storagePath={file.storagePath} datasetId={dataset?.id} />
        </Box>
      );
    });
  }, [dataset?.id, datastoreTables, selectedTable.fileName, validation]);

  return (
    dataset != null && (
      <Grid container sx={{ height: '100%', position: 'relative' }}>
        <Backdrop
          data-cy="datastore-tables-backdrop"
          open={tablesBackdropOpen}
          sx={{ position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress />
        </Backdrop>
        <Grid item xs={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Card sx={{ height: '100%', p: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1, fontWeight: 'bold' }}>Tables</Box>
              <UploadFileButtons allowZipUpload={!isDatastoreLoading && datastoreTables.length === 0} />
            </Box>
            <Box sx={{ m: 1 }}></Box>
            {datastoreTables.length === 0 && !isDatastoreLoading ? (
              <Typography variant="h6">
                {t('commoncomponents.datastore.details.empty.text', 'No file have been uploaded in this dataset')}
              </Typography>
            ) : (
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>{tablesList}</Box>
            )}
          </Card>
        </Grid>

        <Grid item xs={9} sx={{ height: '100%', overflowY: 'auto' }}>
          {selectedTable.fileName && (
            <TableDetails fileName={selectedTable.fileName} storagePath={selectedTable.fileStoragePath} />
          )}
        </Grid>
      </Grid>
    )
  );
};
