import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, Snackbar, Alert } from '@mui/material';

const GenericAutoCompleteInput = ({
  value,
  label,
  onChange,
  options: initialOptions,
  onNewOption,
  editMode,
  disabled,
  fullWidth,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const textFieldRef = useRef(null);
  const options = useMemo(() => {
    if (value && !initialOptions?.includes(value)) return [...initialOptions, value];
    return initialOptions;
  }, [initialOptions, value]);
  useEffect(() => {
    if (!options?.includes(value)) {
      setInputValue('');
    } else {
      setInputValue(value);
    }
  }, [value, options]);

  const handleNewOption = useCallback(
    (newOption) => {
      if (newOption && !options?.includes(newOption)) {
        onNewOption(newOption);
        onChange(null, newOption);
      } else {
        setOpenSnackbar(true);
      }
    },
    [onNewOption, onChange, options]
  );

  const handleSnackbarClose = useCallback(() => {
    setOpenSnackbar(false);
  }, []);

  return (
    <>
      <Autocomplete
        freeSolo
        value={value}
        inputValue={inputValue}
        disabled={disabled}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string' && newValue.startsWith('Créer ')) {
            const newOption = newValue.replace('Créer ', '').replace(/"/g, '');
            handleNewOption(newOption);
          } else {
            onChange(event, newValue);
          }
          if (textFieldRef.current) {
            textFieldRef.current.blur();
          }
        }}
        options={options || []}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        filterOptions={(options, state) => {
          const filtered = options?.filter((option) =>
            option?.toLowerCase()?.includes(state?.inputValue?.toLowerCase())
          );
          if (editMode && onNewOption && state.inputValue !== '' && !filtered.includes(state.inputValue)) {
            filtered.push(`Créer ${state.inputValue}`);
          }
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth} // Utilisation de fullWidth
            variant="outlined"
            sx={{ width: fullWidth ? '100%' : 300 }} // Application conditionnelle du style
            inputRef={textFieldRef}
            inputProps={{
              ...params.inputProps,
              'data-testid': `input-${label.replace(/\s+/g, '-').toLowerCase()}`,
            }}
          />
        )}
      />

      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="warning">
          Cette option existe déjà !
        </Alert>
      </Snackbar>
    </>
  );
};

GenericAutoCompleteInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  onNewOption: PropTypes.func,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool, // Correction ici
};

GenericAutoCompleteInput.defaultProps = {
  editMode: true,
  disabled: false,
  fullWidth: false, // Valeur par défaut si fullWidth n'est pas spécifiée
  options: [],
};

export default GenericAutoCompleteInput;
