import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Error, ExpandMore } from '@mui/icons-material';
import { Box, Card, List, ListItem, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useDatastoreTables,
  useFetchAllWorkspaceFiles,
  useResetValidationState,
  useSelectedDataset,
  useValidationState,
} from '../../../../state/hooks/DatastoreHooks';
import { useUpdateDatastoreAssociatedRunners } from '../../../../state/hooks/RunnerHooks';
import { StatusChip } from '../StatusChip';
import { DataStoreOptions, StartCheckingButton, PushDataStoreButton } from './components';

const DATASTORE_POLLING_DELAY = 5000;

const useStyles = makeStyles((theme) => ({
  datasetHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const DataStoreItemDetails = () => {
  const classes = useStyles();
  const dataset = useSelectedDataset();
  const datastoreTables = useDatastoreTables();
  const validation = useValidationState();
  const fetchAllWorkspaceFiles = useFetchAllWorkspaceFiles();
  const resetValidationState = useResetValidationState();

  const [isValidationRunning, setIsValidationRunning] = useState(false);
  const [isPushRunning, setIsPushRunning] = useState(false);
  const updateDataStoreAssociatedRunners = useUpdateDatastoreAssociatedRunners();

  const lastValidationRunnerStatus = useRef(null);
  const validationRunnerPollingIntervalId = useRef(null);
  const pushRunnerPollingIntervalId = useRef(null);
  const clearIntervals = () => {
    clearInterval(validationRunnerPollingIntervalId.current);
    clearInterval(pushRunnerPollingIntervalId.current);
  };

  const startDisabled = useMemo(() => datastoreTables.length === 0, [datastoreTables]);

  const isRunningState = (state) => ['Unknown', 'Running'].includes(state);
  const updateRunningStatus = (state, setter) => setter(isRunningState(state));

  const monitorRunnerStatus = (runnerInfos, runningStatusSetter, type) => {
    const state = runnerInfos?.state;
    updateRunningStatus(state, runningStatusSetter);
    // Force validation status update if:
    //  - the type of the monitored runner is "validation" (ignore "push" runners)
    //  - the validation status has changed since
    //  - the new status is "Successful"
    //  - the previous status was not null (i.e. if it was running)
    if (type === 'validation' && lastValidationRunnerStatus.current !== state) {
      if (state === 'Successful' && lastValidationRunnerStatus.current != null) {
        fetchAllWorkspaceFiles();
        resetValidationState();
      }
      lastValidationRunnerStatus.current = state;
    }

    if (isRunningState(state)) {
      return setInterval(() => {
        updateRunningStatus(state, runningStatusSetter);
        updateDataStoreAssociatedRunners(dataset?.id);
      }, DATASTORE_POLLING_DELAY);
    }
  };

  useEffect(() => {
    clearIntervals();
    validationRunnerPollingIntervalId.current = monitorRunnerStatus(
      dataset?.associatedRunners?.validation,
      setIsValidationRunning,
      'validation'
    );
    pushRunnerPollingIntervalId.current = monitorRunnerStatus(dataset?.associatedRunners?.push, setIsPushRunning);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, updateDataStoreAssociatedRunners]);

  useEffect(() => {
    return () => {
      lastValidationRunnerStatus.current = null;
    };
  }, [dataset?.id]);

  useEffect(() => clearIntervals, []);

  const validationStatusChip = useMemo(() => {
    if (validation?.status == null) return null;

    return <StatusChip size="medium" status={validation.status} sx={{ ml: 2 }} />;
  }, [validation?.status]);

  return (
    <Box sx={{ height: '100%' }}>
      <Card className={classes.datasetHeader} sx={{ p: 2, display: 'flex' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">{dataset?.name}</Typography>
            {validationStatusChip}
          </Box>
          <Typography sx={{ fontStyle: 'italic', pt: 2 }}>{dataset?.description}</Typography>
        </Box>
        <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <DataStoreOptions />
          <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'row' }}>
            <StartCheckingButton running={isValidationRunning} disabled={startDisabled || isPushRunning} />
            <PushDataStoreButton
              running={isPushRunning}
              disabled={startDisabled || validation.status !== 'valid' || isValidationRunning}
            />
          </Box>
        </Box>
      </Card>
      {validation.details.length > 0 && (
        <Accordion>
          <AccordionSummary sx={{ color: 'error' }} expandIcon={<ExpandMore />}>
            <Error color="error" />
            <Typography color="error">{validation.details.length} erreur(s) de validation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
              {validation.details.map((detail) => (
                <ListItem key={detail} sx={{ display: 'list-item' }}>
                  {detail}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
