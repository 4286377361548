import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import equal from 'deep-equal';
import { AgGridUtils, CSVUtils, FileBlobUtils } from '@cosmotech/core';
import { UPLOAD_FILE_STATUS_KEY } from '@cosmotech/ui';
import { useVecteurDonnees } from './hooks/VecteurDonneesHook';

const VecteurDonnees = ({ context }) => {
  const { addTechVar, assetClass, equipments, scenarioId } = useVecteurDonnees();
  const equipmentsNames = useMemo(() => {
    return Object.keys(equipments ?? {});
  }, [equipments]);

  const exportCsv = useCallback(
    (rows) => {
      const columns = Object.keys(equipments[assetClass][0]).map((col) => ({
        field: col,
        acceptsEmptyFields: true,
      }));
      const fileContent = AgGridUtils.toCSV(rows, columns);
      FileBlobUtils.downloadFileFromData(fileContent, `${assetClass}.csv`);
    },
    [equipments, assetClass]
  );

  const _buildRows = (rows, hasHeader, cols) => {
    const formattedData = [];
    const startIndex = hasHeader ? 1 : 0;
    for (let rowIndex = startIndex; rowIndex < rows.length; rowIndex++) {
      const row = {};
      cols.forEach((col) => {
        const colIndex = rows[0].findIndex((el) => el === col.field);
        if (colIndex !== -1) {
          row[col.field] = rows[rowIndex][colIndex];
        } else {
          row[col.field] = undefined;
        }
      });
      formattedData.push(row);
    }
    return formattedData;
  };

  if (!equipments) return <span>Aucune donnée à afficher.</span>;
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6} style={{ marginTop: '10px' }}>
          <Autocomplete
            options={equipmentsNames}
            value={equipmentsNames.find((option) => option === (assetClass ?? '')) || null}
            onChange={(event, newValue) => {
              event.preventDefault();
              event.stopPropagation();
              addTechVar({ assetClass: newValue }, scenarioId);
            }}
            renderInput={(params) => <TextField {...params} label="Asset Class" fullWidth />}
          />
        </Grid>
        <Controller
          name={assetClass ?? ''}
          render={({ field }) => {
            const { value, onChange } = field;
            const baseValue = value?.name?.includes(assetClass)
              ? (value.rows ?? equipments[assetClass])
              : equipments[assetClass];
            return (
              <>
                <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!assetClass}
                    onClick={() => exportCsv(baseValue)}
                  >
                    Télécharger le CSV
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    component="label"
                    disabled={!assetClass || !context.editMode}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      event.target.value = null;
                      const reader = new FileReader();
                      reader.onload = function (event) {
                        const fileContent = event.target.result;
                        const prevColumns = Object.keys(baseValue[0]);
                        const fileContentFirstLine = fileContent.split('\n')[0].split(',');
                        const columnsNames = Array.from(new Set([...prevColumns, ...fileContentFirstLine]));
                        const columns = columnsNames.map((name) => ({ field: name, acceptsEmptyFields: true }));
                        const newValue = _buildRows(CSVUtils.read(fileContent), true, columns);
                        const final = [];
                        const acceptedIds = baseValue?.map((value) => value.Identifiant);
                        const filteredNewValue = newValue.filter((line) => acceptedIds.includes(line.Identifiant));
                        const extraKeys = new Set();
                        baseValue?.forEach((value) => {
                          const correspondingValue = filteredNewValue.find(
                            (line) => line.Identifiant === value.Identifiant
                          );
                          if (!correspondingValue || equal(value, correspondingValue)) {
                            final.push(value);
                          } else {
                            const newLine = {};
                            for (const [cellKey, cellValue] of Object.entries(correspondingValue)) {
                              if (cellValue != null) {
                                if (!Object.hasOwn(value, cellKey)) {
                                  newLine[cellKey] = cellValue;
                                  extraKeys.add(cellKey);
                                } else {
                                  newLine[cellKey] = cellValue;
                                }
                              } else {
                                newLine[cellKey] = value[cellKey];
                              }
                            }
                            final.push(newLine);
                          }
                        });
                        let result = [];
                        const extraKeysArray = Array.from(extraKeys);
                        if (extraKeysArray.length > 0) {
                          extraKeys.forEach((key) => {
                            final.forEach((line) => {
                              if (!Object.hasOwn(line, key)) result.push({ ...line, [key]: null });
                              else result.push(line);
                            });
                          });
                        } else {
                          result = [...final];
                        }
                        onChange({
                          ...value,
                          name: `${assetClass}.csv`,
                          status: UPLOAD_FILE_STATUS_KEY.READY_TO_UPLOAD,
                          rows: result,
                        });
                      };
                      reader.readAsText(file);
                    }}
                  >
                    Charger un nouveau CSV
                    <input accept=".csv" style={{ display: 'none' }} id="upload-csv-file" type="file" />
                  </Button>
                </Grid>
              </>
            );
          }}
        />
      </Grid>
    </div>
  );
};

VecteurDonnees.propTypes = {
  context: PropTypes.object,
};

export default VecteurDonnees;
