import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider, Backdrop, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  useDatastoreFiles,
  useFetchAllWorkspaceFiles,
  useGetWorkspaceFiles,
  useIsLoading,
  useSelectedDataset,
  useSetLoading,
  useResetValidationState,
  useSetValidationState,
  useUpdateValidationState,
  useValidationState,
} from '../../state/hooks/DatastoreHooks';
import { useDataStoreFileDownload } from './DataStoreHooks';
import { CreateDataStoreItemButton, DataStoreItemsList, DataStoreItemDetails, DataStoreTablesList } from './components';

const DataStore = () => {
  const { t } = useTranslation();
  const loading = useIsLoading();
  const setLoading = useSetLoading();
  const validationState = useValidationState();
  const resetValidationState = useResetValidationState();
  const setValidationState = useSetValidationState();
  const updateValidationState = useUpdateValidationState();

  const dataset = useSelectedDataset();
  const datastoreFiles = useDatastoreFiles();

  const isMounted = useRef(true);

  const downloadFile = useDataStoreFileDownload();
  const fetchAllFiles = useFetchAllWorkspaceFiles();
  const workspaceFiles = useGetWorkspaceFiles();

  useEffect(() => {
    return () => {
      setLoading(true);
      resetValidationState();
    };
  }, [dataset?.id, setLoading, resetValidationState]);

  useEffect(() => {
    const applyEffect = async () => {
      isMounted.current = true;

      const validationFile = datastoreFiles.find((file) => file.name === '_validation.json');

      if (!validationFile) {
        updateValidationState({ status: 'draft' });
        setLoading(false);
        return;
      }

      try {
        if (validationState?.status == null || validationState?.status === 'draft') {
          const { data } = await downloadFile(validationFile.storagePath);
          if (isMounted.current) setValidationState(data);
        }
      } catch (error) {
        console.error('Error downloading validation file:', error);
      } finally {
        setLoading(false);
      }
    };

    applyEffect();
  }, [datastoreFiles, downloadFile, setLoading, setValidationState, updateValidationState, validationState?.status]);

  useEffect(() => {
    isMounted.current = true;
    if (workspaceFiles.length === 0) {
      setLoading(true);
      fetchAllFiles();
    }

    return () => {
      isMounted.current = false;
      resetValidationState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container direction="column" sx={{ height: '100%', p: 2, overflow: 'auto', display: 'flex' }} wrap="nowrap">
      <Backdrop
        data-cy="datastore-view-backdrop"
        open={loading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <Grid item id="top">
        <Grid container>
          <Grid item xs={3}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1em',
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '1em',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <DataStoreItemsList />
                </Box>
                <CreateDataStoreItemButton />
              </Box>
              {dataset !== null && dataset?.tags?.includes('datastore') && (
                <Box>
                  <Typography sx={{ fontSize: 14 }}>
                    <strong>{t('commoncomponents.datastore.details.metadata.owner', 'Owner:')} </strong>
                    {dataset.ownerName}
                  </Typography>
                  <Typography component="span" sx={{ fontSize: 14 }}>
                    <strong>{t('commoncomponents.datastore.details.metadata.creationDate', 'Creation date:')} </strong>
                    {new Date(dataset.creationDate).toLocaleString(undefined, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={9}>
            {dataset == null || !dataset?.tags?.includes('datastore') ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Typography variant="h6">
                  {t('commoncomponents.datastore.details.placeholder.text', 'Please select a datastore to work with')}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ width: '100%', height: '100%' }}>
                <DataStoreItemDetails />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ my: 1 }} />
      <Grid item id="files-list" sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <DataStoreTablesList />
      </Grid>
    </Grid>
  );
};

export default DataStore;
