import React, { useCallback, useRef } from 'react';
import { UploadFile } from '@mui/icons-material';
import { Button } from '@mui/material';
import rfdc from 'rfdc';
import {
  useDatasetStorageRoot,
  useDeleteDatastoreFiles,
  useIsArchiveAvailable,
  useIsReportAvailable,
  useUploadDatastoreFiles,
  useSetLoading,
  useSetValidationState,
  useValidationState,
} from '../../../../../../../../state/hooks/DatastoreHooks';

const clone = rfdc();

export const NewFileButton = () => {
  const deleteFiles = useDeleteDatastoreFiles();
  const uploadFiles = useUploadDatastoreFiles();
  const storageRoot = useDatasetStorageRoot();
  const isArchiveAvailable = useIsArchiveAvailable();
  const isReportAvailable = useIsReportAvailable();
  const setLoading = useSetLoading();
  const validation = useValidationState();
  const setValidationState = useSetValidationState();

  const inputRef = useRef();

  const handleFileUpload = useCallback(
    async (event) => {
      const filesToUpload = [];
      const files = event.target.files;
      if (files.length === 0) return;
      setLoading(true);
      const updatedValidation = clone(validation);
      updatedValidation.status = validation.status ? 'modified' : 'draft';
      for (const file of files) {
        filesToUpload.push({ data: file, path: storageRoot });
        updatedValidation.tables[file.name] = {
          status: Object.keys(updatedValidation.tables).includes(file.name) ? 'modified' : 'draft',
          details: [],
        };
      }

      const validationBlob = new Blob([JSON.stringify(updatedValidation)]);
      const validationFile = new File([validationBlob], '_validation.json');
      filesToUpload.push({ data: validationFile, path: storageRoot });

      const filesToDelete = [];
      if (isArchiveAvailable) filesToDelete.push(`${storageRoot}datastore.zip`);
      if (isReportAvailable) filesToDelete.push(`${storageRoot}validation_report.txt`);

      uploadFiles(filesToUpload);
      if (filesToDelete.length > 0) deleteFiles(filesToDelete);
      setValidationState(updatedValidation);

      // FIXME: set table status from saga?
      setLoading(false);
      inputRef.current.value = '';
    },
    [
      deleteFiles,
      uploadFiles,
      storageRoot,
      validation,
      setLoading,
      isArchiveAvailable,
      isReportAvailable,
      setValidationState,
    ]
  );

  return (
    <>
      <input
        ref={inputRef}
        hidden
        id="new-file-upload"
        type="file"
        onChange={handleFileUpload}
        accept=".csv,.CSV"
        multiple
      />
      <label htmlFor="new-file-upload">
        <Button variant="contained" component="span" startIcon={<UploadFile />}>
          Charger au format CSV
        </Button>
      </label>
    </>
  );
};
