import { t } from 'i18next';
import { call, takeEvery, put, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASTORE_ACTIONS } from '../../../commons/DatastoreConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

const getWorkspaceId = (state) => state.workspace.current?.data?.id;
const getOrganizationId = (state) => state.organization.current?.data?.id;

export function* fetchAllWorkspaceFiles(action) {
  yield put({ type: DATASTORE_ACTIONS.SET_LOADING, loading: true });
  const workspaceId = yield select(getWorkspaceId);
  const organizationId = yield select(getOrganizationId);

  try {
    const { data: files } = yield call(Api.Workspaces.findAllWorkspaceFiles, organizationId, workspaceId);
    yield put({
      type: DATASTORE_ACTIONS.SET_WORKSPACE_FILE_PATHS,
      workspaceFilePaths: files.map((file) => file.fileName),
    });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t(
          'commoncomponents.datastore.errors.cannotFetchWorkspaceFiles',
          'A problem occurred when fetching workspace files.'
        )
      )
    );
  }

  yield put({ type: DATASTORE_ACTIONS.SET_LOADING, loading: false });
}

function* fetchAllWorkspaceFilesSaga() {
  yield takeEvery(DATASTORE_ACTIONS.TRIGGER_SAGA_FETCH_ALL_WORKSPACE_FILES, fetchAllWorkspaceFiles);
}

export default fetchAllWorkspaceFilesSaga;
