import React, { useCallback } from 'react';
import { FolderZip } from '@mui/icons-material';
import { Button } from '@mui/material';
import * as JSZip from 'jszip';
import {
  useDatasetStorageRoot,
  useUploadDatastoreFiles,
  useSetLoading,
} from '../../../../../../../../state/hooks/DatastoreHooks';

export const NewZipFileButton = () => {
  const storageRoot = useDatasetStorageRoot();
  const setLoading = useSetLoading();
  const uploadFiles = useUploadDatastoreFiles();

  const handleFileUpload = useCallback(
    async (event) => {
      const files = event.target.files;
      if (files.length === 0) return;
      setLoading(true);
      try {
        const zip = new JSZip();
        const contents = await zip.loadAsync(files[0]);
        const fileNames = Object.keys(contents.files).filter(
          (filename) => !filename.endsWith('/') && filename.toLowerCase().endsWith('.csv')
        );
        const filesToUpload = [];
        for (const filename of fileNames) {
          const fileBlob = await zip.file(filename).async('blob');
          // flatening fileName to upload file and not the whole zip structure including folders
          // implies that if 2 or more files share the same name in different directories in
          // the zip, the last uploaded one will override the others.
          const flatFileName = filename.split('/').pop();
          const fileData = new File([fileBlob], flatFileName);
          filesToUpload.push({ data: fileData, path: storageRoot });
        }
        uploadFiles(filesToUpload);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [uploadFiles, storageRoot, setLoading]
  );

  return (
    <>
      <input hidden id="new-zip-file-upload" type="file" onChange={handleFileUpload} accept=".zip" multiple />
      <label htmlFor="new-zip-file-upload">
        <Button variant="contained" component="span" startIcon={<FolderZip />}>
          Charger un ZIP
        </Button>
      </label>
    </>
  );
};
