import { useCallback } from 'react';
import {
  useSelectedDataset,
  useSelectDataset,
  useSetLoading,
  useVisibleDatastores,
} from '../../../../state/hooks/DatastoreHooks';
import { useOrganizationData } from '../../../../state/hooks/OrganizationHooks';
import { useUpdateDatastoreAssociatedRunners } from '../../../../state/hooks/RunnerHooks';

export const useDataStoreList = () => {
  const setLoading = useSetLoading();
  const userPermissionsInCurrentOrganization = useOrganizationData()?.security?.currentUserPermissions ?? [];
  const visibleDatastores = useVisibleDatastores();
  const currentDataset = useSelectedDataset();
  const selectDataset = useSelectDataset();
  const updateDataStoreAssociatedRunner = useUpdateDatastoreAssociatedRunners();

  const selectDatasetAndRunner = useCallback(
    (dataset) => {
      selectDataset(dataset.id);
      updateDataStoreAssociatedRunner(dataset.id);
    },
    [selectDataset, updateDataStoreAssociatedRunner]
  );

  return {
    userPermissionsInCurrentOrganization,
    dataStoreItems: visibleDatastores,
    currentDataset,
    selectDatasetAndRunner,
    setLoading,
  };
};
