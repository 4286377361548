import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import EditableTable from '../../components/EditableTable';
import { useNiveauDePriorisationTab } from './hooks/NiveauDePriorisationHook';

const NiveauPriorisationTab = ({ editMode }) => {
  const { sortedNiveauDePriorisation } = useNiveauDePriorisationTab();

  const niveauDePriorisationRows = useMemo(() => {
    if (sortedNiveauDePriorisation?.length === 0)
      return [{ Identifiant: 'DefautNiveauPriorisation', RangPriorisation: 1, SchemaPriorisation: 'RiskBased' }];
    return sortedNiveauDePriorisation;
  }, [sortedNiveauDePriorisation]);

  const columns = [
    {
      field: 'Identifiant',
      label: 'Niveau',
    },
    {
      field: 'RangPriorisation',
      label: 'Rang de priorisation',
      inputType: 'number',
    },
    {
      field: 'SchemaPriorisation',
      label: 'Schema de priorisation',
      inputType: 'select',
      options: ['RiskBased', 'ProbabilityBased', 'ConsequenceBased', 'OpportunityBased'],
    },
  ];

  return (
    <Box width={'100%'}>
      <Controller
        name="NiveauDePriorisation"
        defaultValue={niveauDePriorisationRows ?? []}
        render={({ field }) => {
          const { value, onChange } = field;
          return <EditableTable columns={columns} rows={value ?? []} onChange={onChange} editMode={editMode} />;
        }}
      />
    </Box>
  );
};

NiveauPriorisationTab.propTypes = {
  editMode: PropTypes.bool,
};
export default NiveauPriorisationTab;
