import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { INGESTION_STATUS } from '../../services/config/ApiConstants';
import Gantt from './Gantt/Gantt';
import { useGanttWizard } from './GanttWizardHook';
import { OpenWizardButton } from './Wizard/OpenWizardButton';

const WizardAndGantt = () => {
  const { status, datasetId, doesDatasetExist, isDatasetTwingraph, datasetIngestionStatus } = useGanttWizard();
  if (!datasetId)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Aucun dataset n&apos;est associé au scénario choisi</Typography>
      </Grid>
    );
  if (!doesDatasetExist)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Le dataset associé au scénario n&apos;a pas pu être trouvé</Typography>
      </Grid>
    );
  if (!isDatasetTwingraph)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Le dataset associé au scénario n&apos;est pas du type twingraph</Typography>
      </Grid>
    );
  if (datasetIngestionStatus !== INGESTION_STATUS.SUCCESS)
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Typography>
          Le dataset associé au scénario n&apos;est pas prêt, son status est {datasetIngestionStatus}
        </Typography>
      </Grid>
    );
  if (status === 'LOADING') return <CircularProgress />;
  return (
    <div>
      <OpenWizardButton />
      <Gantt />
    </div>
  );
};

export default WizardAndGantt;
