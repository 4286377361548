import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import EditableTable from '../../components/EditableTable';
import GenericAutoCompleteInput from '../../components/GenericAutoCompleteInput';
import { useSequenceActions } from './hooks/SequenceActionsHook';

const SequencesActionsTab = ({ selectedSequenceAction, setSelectedSequenceAction, selectedGisement, editMode }) => {
  const { sequencesActions, coutsEtDurees, impacts, familleEquipement, niveauCout, tableNamesMapping } =
    useSequenceActions(selectedGisement);
  const [sequenceActionOptions, setSequenceActionOptions] = useState(
    Array.from(new Set(sequencesActions?.map((sa) => sa.SequenceActions)))
  );
  const [openCoutDureeModal, setOpenCoutDureeModal] = useState(false);
  const [openImpactModal, setOpenImpactModal] = useState(false);
  const [selectedActionId, setSelectedActionId] = useState('');

  const parametresColumns = [
    { field: 'IdentifiantAction', label: 'Action', size: 'medium', sx: { width: '150px' } },
    { field: 'RangExecution', label: "Rang d'exécution", inputType: 'number', min: 0, size: 'small' },
    {
      field: 'CategoriesBudgetaires',
      label: 'Catégories Budgétaires',
      inputType: 'text',
      size: 'medium',
      sx: { width: '200px' },
    },
    {
      field: 'NiveauDefinitionCouts',
      label: 'Niveau Définition Couts',
      inputType: 'select',
      size: 'small',
      options: [
        { label: 'Action', value: 'Action' },
        { label: 'SubAction', value: 'SubAction' },
      ],
    },
    {
      field: 'coutEtDurees',
      label: 'Coûts et Durées',
      inputType: 'button',
      buttonLabel: 'Voir/Éditer',
      onClick: (row) => {
        setOpenCoutDureeModal(true);
        setSelectedActionId(row.IdentifiantAction);
      },
      size: 'small',
    },
    {
      field: 'impacts',
      label: 'Impacts',
      inputType: 'button',
      buttonLabel: 'Voir/Éditer',
      onClick: (row) => {
        setOpenImpactModal(true);
        setSelectedActionId(row.IdentifiantAction);
      },
      size: 'small',
    },
    {
      field: 'CoefficientModificationCouts',
      label: 'Coefficients Modification Coûts',
      inputType: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      size: 'small',
    },
    {
      field: 'CoefficientModificationDuree',
      label: 'Coefficients Modification Durée',
      inputType: 'number',
      min: 0,
      max: 1,
      step: 0.01,
      size: 'small',
    },
    { field: 'ActionLocale', label: 'Action Locale', inputType: 'checkbox', size: 'small' },
    { field: 'ConsignationRequise', label: 'Consignation Requise', inputType: 'checkbox', size: 'small' },
    { field: 'CapitalisationCharges', label: 'Capitalisation Charges', inputType: 'checkbox', size: 'small' },
  ];

  const handleAddSequenceAction = (newSequenceAction) => {
    setSelectedSequenceAction(newSequenceAction);
    setSequenceActionOptions((prevOptions) => [...prevOptions, newSequenceAction]);
  };

  return (
    <Box width={'100%'} sx={{ padding: 2 }}>
      <GenericAutoCompleteInput
        label={"Sélectionner ou créer une séquence d'actions"}
        value={selectedSequenceAction || ''}
        options={sequenceActionOptions}
        onChange={(event, newSequenceAction) => setSelectedSequenceAction(newSequenceAction)}
        onNewOption={handleAddSequenceAction}
        editMode={editMode}
      />
      <Controller
        name={tableNamesMapping.SequencesActions}
        defaultValue={sequencesActions ?? []}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <EditableTable
              columns={parametresColumns}
              rows={value.filter((action) => action.SequenceActions === selectedSequenceAction)}
              onChange={onChange}
              notFilteredRows={value}
              filteringCol={{ field: 'SequenceActions', value: selectedSequenceAction }}
              editMode={editMode && !!selectedSequenceAction}
            />
          );
        }}
      />

      {/* Modals for Coûts et Durées and Impacts */}
      <Dialog maxWidth={'lg'} open={openCoutDureeModal} onClose={() => setOpenCoutDureeModal(false)}>
        <DialogTitle>{'Coûts et Durées'}</DialogTitle>
        <DialogContent>
          <Controller
            name={tableNamesMapping.CoutsEtDureesActions}
            defaultValue={coutsEtDurees ?? []}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <EditableTable
                  columns={[
                    {
                      field: 'NiveauCouts',
                      label: 'Niveau de coût',
                      inputType: 'select',
                      options: Array.from(new Set(niveauCout)),
                    },
                    { field: 'CoutUnitaire', label: 'Coût Unitaire' },
                    { field: 'CoutFixe', label: 'Coût Fixe' },
                    { field: 'DureeUnitaire', label: 'Durée Unitaire' },
                    { field: 'DureeFixe', label: 'Durée Fixe' },
                    {
                      field: 'FamilleEquipement',
                      label: 'Famille Équipement',
                      inputType: 'select',
                      options: [{ label: 'Aucune', value: '' }, ...familleEquipement.map((fe) => fe.Identifiant)],
                    },
                  ]}
                  rows={value?.filter((row) => row.IdentifiantAction === selectedActionId)}
                  onChange={onChange}
                  notFilteredRows={value}
                  filteringCol={{ field: 'IdentifiantAction', value: selectedActionId }}
                  editMode={editMode}
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCoutDureeModal(false)}>{'Fermer'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={'lg'} open={openImpactModal} onClose={() => setOpenImpactModal(false)}>
        <DialogTitle>{'Impacts'}</DialogTitle>
        <DialogContent>
          <Controller
            name={tableNamesMapping.Impacts}
            defaultValue={impacts ?? []}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <EditableTable
                  columns={[
                    {
                      field: 'FamilleEquipementImpactee',
                      label: 'Famille Équipement',
                      inputType: 'select',
                      options: familleEquipement.map((fe) => fe.Identifiant),
                    },
                    {
                      field: 'Type',
                      label: 'Type',
                      inputType: 'select',
                      options: [
                        { label: 'NoAction', value: 'NoAction' },
                        { label: 'Repairs', value: 'Repairs' },
                        { label: 'Replacement', value: 'Replacement' },
                        { label: 'Inspection', value: 'Inspection' },
                        { label: 'Activation', value: 'Activation' },
                        { label: 'Deactivation', value: 'Deactivation' },
                        { label: 'RepairsWithoutInspection', value: 'RepairsWithoutInspection' },
                        { label: 'Refurbishment', value: 'Refurbishment' },
                      ],
                    },
                    {
                      field: 'Impact',
                      label: 'Impact',
                    },
                    { field: 'ImpactPriorisation', label: 'Impact sur la priorité', inputType: 'checkbox' },
                    {
                      field: 'FamilleEquipementRetrofit',
                      label: 'Famille Retrofit',
                      inputType: 'select',
                      options: familleEquipement.map((fe) => fe.Identifiant),
                    },
                  ]}
                  rows={value?.filter((row) => row.IdentifiantAction === selectedActionId)}
                  notFilteredRows={value}
                  filteringCol={{ field: 'IdentifiantAction', value: selectedActionId }}
                  onChange={onChange}
                  editMode={editMode}
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImpactModal(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

SequencesActionsTab.propTypes = {
  selectedSequenceAction: PropTypes.string,
  setSelectedSequenceAction: PropTypes.func.isRequired,
  selectedGisement: PropTypes.string,
  editMode: PropTypes.bool,
};

export default SequencesActionsTab;
