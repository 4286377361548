import React, { useCallback, useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDataStoreList } from './DataStoreItemsListHooks';

export function DataStoreItemsList() {
  const { dataStoreItems, currentDataset, selectDatasetAndRunner, setLoading } = useDataStoreList();

  const handleDataStoreSelection = useCallback(
    (event) => {
      setLoading(true);
      const selectedDatasetId = event.target.value;
      const matchedDataStore = dataStoreItems.find((dataStoreItem) => dataStoreItem.id === selectedDatasetId);
      selectDatasetAndRunner(matchedDataStore);
    },
    [selectDatasetAndRunner, setLoading, dataStoreItems]
  );

  const datastores = useMemo(() => {
    return dataStoreItems.map((dataStoreItem) => (
      <MenuItem value={dataStoreItem.id} key={dataStoreItem.id}>
        {dataStoreItem.name}
      </MenuItem>
    ));
  }, [dataStoreItems]);

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="datastore-select-label">Sélectionnez un magasin de données</InputLabel>
        <Select
          labelId="datastore-select-label"
          id="datastore-select"
          value={currentDataset?.id || ''}
          label="Sélectionez un magasin de données"
          onChange={handleDataStoreSelection}
        >
          {datastores}
        </Select>
      </FormControl>
    </>
  );
}
