import { t } from 'i18next';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DatasetsUtils } from '../../../../utils';
import { DATASET_ACTIONS_KEY, DATASET_PERMISSIONS_MAPPING } from '../../../commons/DatasetConstants';
import { RUNNER_ACTIONS_KEY } from '../../../commons/RunnerConstants';
import { WORKSPACE_ACTIONS_KEY } from '../../../commons/WorkspaceConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

function* getLastRunInfos(runner, organizationId, workspaceId) {
  if (!runner) return;

  let lastRun;
  if (runner.lastRun) {
    const res = yield call(Api.RunnerRuns.getRun, organizationId, workspaceId, runner.id, runner.lastRun.runnerRunId);
    lastRun = res.data;
  }

  const namesMap = { datastore_checking: 'validation', push_datastore: 'push' };
  return {
    id: runner.id,
    lastRunState: lastRun?.state ?? null,
    lastRunId: lastRun?.id ?? null,
    name: namesMap[runner.runTemplateId],
  };
}

const getUserEmail = (state) => state.auth.userEmail;
const getDatastoreRunners = (state) => state.datastore.runners;
const getDataset = (state, datasetId) => state.dataset.list.data.find((dataset) => dataset.id === datasetId);

export function* updateDataStoreAssociatedRunners(action) {
  try {
    const organizationId = action.organizationId;
    const workspaceId = action.workspaceId;
    const datastoreRunners = yield select(getDatastoreRunners);

    const isTargetDatasetId = (param) => param.parameterId === 'source_dataset_id' && param.value === action.datasetId;
    const isTargetDatasetRunner = (runner) => runner.parametersValues.some(isTargetDatasetId);
    let validationRunner = datastoreRunners
      .filter((runner) => runner.runTemplateId === 'datastore_checking')
      .find(isTargetDatasetRunner);
    let pushRunner = datastoreRunners
      .filter((runner) => runner.runTemplateId === 'push_datastore')
      .find(isTargetDatasetRunner);

    const newRuns = action.newRuns; // Should be defined if runners have been created before calling the saga
    if (validationRunner && newRuns?.validation)
      validationRunner = { ...validationRunner, lastRun: newRuns.validation };
    if (pushRunner && newRuns?.push) pushRunner = { ...pushRunner, lastRun: newRuns.push };

    if (validationRunner === undefined || pushRunner === undefined) {
      yield put(
        dispatchSetApplicationErrorMessage(
          { title: t('commoncomponents.banner.corruptedDatastore', 'Corrupted datastore'), status: null },
          t('commoncomponents.banner.datastoreRunnerNotFound', 'At least one of the associated runners is missing')
        )
      );
    }

    // runner state is currently not handled properly, need to fetch last run state instead
    const validationRunnerInfos = yield call(getLastRunInfos, validationRunner, organizationId, workspaceId);
    const pushRunnerInfos = yield call(getLastRunInfos, pushRunner, organizationId, workspaceId);

    const dataset = yield select(getDataset, action.datasetId);
    const { associatedRunners: prevAssociatedRunners } = dataset;

    // updating only if no previous associated runner found
    // or if the state of the run changed in order not to trigger dataset object update
    let updateAssociatedRunnersInStore = false;
    for (const runnerInfos of [validationRunnerInfos, pushRunnerInfos]) {
      if (!runnerInfos) continue;
      if (!prevAssociatedRunners || prevAssociatedRunners[runnerInfos.name]?.state !== runnerInfos.lastRunState) {
        updateAssociatedRunnersInStore = true;
        if (runnerInfos.lastRunState === 'Successful') {
          const {
            data: { tags },
          } = yield call(Api.Datasets.findDatasetById, organizationId, action.datasetId);
          yield put({
            type: DATASET_ACTIONS_KEY.UPDATE_DATASET,
            datasetId: action.datasetId,
            datasetData: { ...dataset, tags },
          });
        }
      }
    }

    // Patch associatedRunners in list of datasets in redux
    if (updateAssociatedRunnersInStore) {
      yield put({
        type: DATASET_ACTIONS_KEY.UPDATE_DATASET,
        datasetId: action.datasetId,
        datasetData: {
          associatedRunners: {
            validation: {
              id: validationRunnerInfos?.id,
              lastRunId: validationRunnerInfos?.lastRunId,
              state: validationRunnerInfos?.lastRunState,
            },
            push: {
              id: pushRunnerInfos?.id,
              lastRunId: pushRunnerInfos?.lastRunId,
              state: pushRunnerInfos?.lastRunState,
            },
          },
        },
      });
    }

    // The "push" runner creates a dataset that the webapp must fetch. This block checks if such a dataset exists and
    // must be fetched
    if (pushRunnerInfos?.lastRunState === 'Successful') {
      const { data: newRunner } = yield call(Api.Runners.getRunner, organizationId, workspaceId, pushRunner.id);

      const idOfDatasetToFetch = newRunner?.parametersValues.find(
        (param) => param.parameterId === 'last_confirmed_dataset_id'
      )?.value;
      if (idOfDatasetToFetch) {
        const existingDataset = yield select(getDataset, idOfDatasetToFetch);
        if (existingDataset === undefined) {
          try {
            const { data: dataset } = yield call(Api.Datasets.findDatasetById, organizationId, idOfDatasetToFetch);
            const userEmail = yield select(getUserEmail);
            DatasetsUtils.patchDatasetWithCurrentUserPermissions(dataset, userEmail, DATASET_PERMISSIONS_MAPPING);
            yield put({ type: DATASET_ACTIONS_KEY.ADD_DATASET, ...dataset });
            yield put({ type: WORKSPACE_ACTIONS_KEY.LINK_TO_DATASET, datasetId: dataset.id, workspaceId });
          } catch (error) {
            if (error.status !== 404) console.error(error); // Ignore silently when dataset has been manually deleted
          }
        }
      }
    }

    // TODO : fetch dataset info + update redux
    // to see confirmed datastore in dataset manager without manual refresh
    // select dataset with SELECT action key
    // yield put({
    //   type: DATASET_ACTIONS_KEY.GET_ALL_DATASETS,
    //   organizationId,
    // });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t('commoncomponents.banner.runnerNotUpdated', "Runner hasn't been updated")
      )
    );
  }
}

export function* updateDataStoreAssociatedRunnersSaga() {
  yield takeEvery(
    RUNNER_ACTIONS_KEY.TRIGGER_SAGA_UPDATE_DATASTORE_ASSOCIATED_RUNNERS,
    updateDataStoreAssociatedRunners
  );
}
